<script setup lang="ts">
import { RollerItem } from 'vue-roller'
import 'vue-roller/dist/style.css'
import type { NovaBoxFoundingCounterRollerProps } from './NovaBoxFoundingCounterRoller.types'

const props = defineProps<NovaBoxFoundingCounterRollerProps>()

const { to } = toRefs(props)
const ready = ref(false)
const split = computed(() => to.value.split(''))

onMounted(() => {
  ready.value = true
})
</script>

<template>
  <div v-if="!ready" class="box-roller">
    <NovaLoadingIndicator :height="60" :bg-bright="'light'" :fill="false" />
  </div>

  <ClientOnly v-else>
    <div class="box-roller">
      <RollerItem :char="''" :default-char="''" class="dummy" />
      <RollerItem v-for="(char, index) in split" :key="index" :char="char" />
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.box-roller {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  width: 100%;

  .roller {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 6px;
    width: 100%;
  }

  :deep(.roller-item) {
    position: relative;
    width: 100% !important;
    height: 100%;
    padding: 0;
    overflow: hidden;
    flex-grow: 1;
    color: $color-white;
    overflow: hidden;

    &.dummy {
      &:before {
        background-image: url('assets/images/LMBanner/lm-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
      }

      .roller-item__wrapper {
        display: none;
      }
    }

    &::before {
      display: block;
      content: '';
      padding-top: 130%;
      background-color: #151513;
      border-radius: 3px;
      border: 3px solid #565656;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 60%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 13%,
        rgba(255, 255, 255, 0.4) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .roller-item__wrapper {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 70px;
      font-weight: 700;
      mask-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 10%,
        rgb(0, 0, 0) 10%,
        rgb(0, 0, 0) 90%,
        rgba(255, 255, 255, 0) 90%
      );
      -webkit-mask-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 10%,
        rgb(0, 0, 0) 10%,
        rgb(0, 0, 0) 90%,
        rgba(255, 255, 255, 0) 90%
      );

      @include custom-break-point(900) {
        font-size: 60px;
      }

      @include custom-break-point(850) {
        font-size: 50px;
      }

      @include custom-break-point(530) {
        font-size: 40px;
      }

      @include custom-break-point(470) {
        font-size: 30px;
      }

      @include custom-break-point(390) {
        font-size: 20px;
      }
    }

    .roller-item__wrapper__list {
      //position: relative;
      //display: flex;
      //height: 100%;
      //width: 100%;
      //align-items: center;
      //box-sizing: border-box;

      .roller-item__wrapper__list__item {
        //line-height: initial;
        //height: initial;
        //height: 100%;
        //line-height: 70px;
      }
    }
  }
}
</style>
