<script setup lang="ts">
import type { FundTab, FundTabType } from './NovaLayoutFundHeader.types'
import { ROUTES } from '@configs'

const { t, locale, messages } = useI18n()
const tabs = computed(
  () =>
    Object.keys(
      (messages.value[locale.value].fund as any).page.header
        .tabs as FundTabType[]
    ).map((key) => ({
      id: key,
      name: t(`fund.page.header.tabs.${key}`),
    })) as FundTab[]
)
const initTab = computed(() => {
  const name = useGetRouteName()

  switch (name) {
    case ROUTES.FUND.name:
      return 'comingSoon'
    case ROUTES.FUND_HISTORY.name:
    case ROUTES.FUND_HISTORY_DETAIL.name:
      return 'history'
    case ROUTES.FUND_GAUDI_FUND.name:
      return 'gaudiFund'
    default:
      return undefined
  }
})

const onChangeTab = (tab: FundTab) => {
  switch (tab.id) {
    case 'comingSoon':
      useNavigations({ url: ROUTES.FUND.path })
      break
    case 'history':
      useNavigations({ url: ROUTES.FUND_HISTORY.path })
      break
    case 'gaudiFund':
      useNavigations({ url: ROUTES.FUND_GAUDI_FUND.path })
      break
  }
}
</script>

<template>
  <section class="fund-header">
    <NovaBoxFoundingCounter />
    <NovaTab
      :tab-value="tabs"
      :init-tab="initTab"
      :align="'center'"
      :horizon-padding="20"
      class="tabs"
      @on-change-tab="onChangeTab"
    />
  </section>
</template>

<style lang="scss" scoped>
.fund-header {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  @include mobile {
    padding-top: 0;
  }
  .image-container {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    @include mobile {
      border-radius: 0;
    }
    .locale-group {
      font-size: 0;
      line-height: 0;

      img {
        width: 100%;
        border-radius: 10px 10px 0 0;
      }

      img.mobile {
        display: none;
      }

      @include mobile {
        img.desktop {
          display: none;
        }
        img.mobile {
          display: block;
        }
      }
    }
  }
  .banners {
  }

  .tabs {
    border-radius: 0 0 18px 18px;
    background-color: $color-bg-3;
  }
}
</style>
