<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

const etcStore = useEtcStore()
const { date } = useDateFormat()
const fetchFoundingLmInterval = ref<ReturnType<typeof setInterval>>()
const loading = ref(false)
const error = ref(false)
const amountFormat = '000000000'
const fundingAmount = ref(amountFormat)
const fundingAmountGaudi = ref(amountFormat)
const fundingAmountEur = ref<string>('0')
const fundingAmountUsd = ref<string>('0')
const lastUpdateDate = ref('')

onBeforeMount(async () => {
  await fetchFunding()
  scheduler()
})

onBeforeUnmount(() => {
  clearInterval(fetchFoundingLmInterval.value)
})

const fetchFunding = async () => {
  if (loading.value) return

  try {
    loading.value = true
    error.value = false
    await useSleeper()
    const {
      quantity,
      quantityByUSD,
      gaudiQuantity,
      gaudiQquantityByEUR,
      updateDt,
    } = await etcStore.fetchFunding()

    // 1. 일반 펀드
    const _fundingAmount = transferAmountToPattern(quantity)
    fundingAmount.value = _fundingAmount
    fundingAmountUsd.value = String(
      useFormatThousandsSeparators(quantityByUSD, 2)
    )

    // 2. 가우디 펀드
    const _fundingAmountGaudi = transferAmountToPattern(gaudiQuantity)
    fundingAmountGaudi.value = _fundingAmountGaudi
    fundingAmountEur.value = String(
      useFormatThousandsSeparators(gaudiQquantityByEUR, 2)
    )

    lastUpdateDate.value = date({ date: updateDt, format: 'YYYY-MM-DD' })
  } catch {
    error.value = true
  } finally {
    loading.value = false
  }
}

const scheduler = () => {
  fetchFoundingLmInterval.value = setInterval(() => {
    fetchFunding()
  }, 10000)
}

const transferAmountToPattern = (amount: number) => {
  const str = String(amount)
  const prefix = amountFormat.substring(0, amountFormat.length - str.length)

  return prefix + str
}
const swiperOptions = {
  navigation: true,
  pagination: {
    clickable: true,
  },
  loop: true,
  loopAdditionalSlides: 1,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  modules: [Navigation, Pagination, Autoplay],
}
</script>

<template>
  <div class="founding-counter">
    <Swiper v-bind="swiperOptions">
      <SwiperSlide>
        <div class="lm-banner gaudi">
          <div class="title">
            <div class="gaudi-logo">
              <img
                src="@/assets/images/LMBanner/lm-gaudi.png?url"
                alt="gaudi"
                title="gaudi"
              />
            </div>
            <p class="message">GAUDI Fund</p>
          </div>

          <div class="roller-wrap">
            <div class="inner">
              <NovaBoxFoundingCounterRoller
                :to="fundingAmountGaudi"
                :from="amountFormat"
                class="flip-box"
              />
              <div class="lm-logo">
                <img
                  src="@/assets/images/LMBanner/lm-leisure-meta-white.png?url"
                  alt="LM LEISURE META"
                  title="LM LEISURE META"
                />
              </div>
            </div>
            <div class="text-box">
              <div class="date">
                <span>[</span>{{ lastUpdateDate }}<span>]</span>
              </div>

              <div class="text">
                <p class="title">Conversion in EUR</p>
                <p class="number">€ {{ fundingAmountEur }}<small>EUR</small></p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="swiper-slide">
        <div class="lm-banner">
          <div class="title">
            <div class="title">Web3 Creator Fund</div>
          </div>

          <div class="roller-wrap">
            <div class="inner">
              <NovaBoxFoundingCounterRoller
                :to="fundingAmount"
                :from="amountFormat"
                class="flip-box"
              />
              <div class="lm-logo">
                <img
                  src="@/assets/images/LMBanner/lm-leisure-meta.png?url"
                  alt="LM LEISURE META"
                  title="LM LEISURE META"
                />
              </div>
            </div>
            <div class="text-box">
              <div class="date">
                <span>[</span>{{ lastUpdateDate }}<span>]</span>
              </div>

              <div class="text">
                <p class="title">Conversion in USD</p>
                <p class="number">$ {{ fundingAmountUsd }}<small>USD</small></p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style lang="scss" scoped>
.founding-counter {
  @include tablet {
    .swiper {
      :deep(.swiper-button-prev) {
        left: 0;
      }
      :deep(.swiper-button-next) {
        right: 0;
      }
    }
  }

  .lm-banner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 40px;
    min-height: 196px;
    background: $color-bg-3 url('assets/images/LMBanner/lmbanner-bg.jpg')
      no-repeat center center/cover;

    @include tablet {
      padding: 15px 30px 25px 30px;
    }

    > .title {
      font-size: 26px;
      padding: 20px 30px;
      color: #3c3c3c;
      line-height: 1.1em;
      font-weight: 700;
      width: 200px;
      border-right: 1px solid #000;
      transition: all 0.2s ease;
      @include tablet {
        width: 150px;
        font-size: 22px;
        padding: 15px;
      }
      @include mobile {
        width: auto;
        font-size: 16px;
        padding: 5px 0;
        border-right: 0;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        margin-top: 10px;
        background-color: #014fa4;
        background-image: linear-gradient(to right, #014fa4 50%, #019b43 50%);
        min-height: 10px;
        @include mobile {
          min-height: 4px;
        }
        @include mobile {
          margin-top: 4px;
        }
      }
    }

    .roller-wrap {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 12px 165px 10px 20px;
      width: calc(100% - 200px);
      justify-content: space-between;

      @include tablet {
        width: calc(100% - 150px);
        padding: 10px 90px 10px 10px;
      }
      @include mobile {
        width: 100%;
        padding: 0;
      }

      .btn-more {
        position: absolute;
        bottom: 16px;
        right: 4px;

        :deep(.label) {
          color: $color-primary;
          font-size: 16px;
          font-weight: 500;
        }

        :deep(.icon) {
          padding-top: 4px;
          color: $color-primary;
          font-size: 16px !important;
        }

        @include tablet {
          bottom: 16px;
          right: 0px;
        }
        @include mobile {
          top: -30px;
          bottom: auto;
          right: -6px;
        }
      }

      > .inner {
        position: relative;
        display: flex;
        align-content: center;
        width: 100%;

        :deep(.box-roller) {
          padding: 5px 0 5px 0;

          @include mobile {
            padding-right: 50px;
          }
        }

        .lm-logo {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -150px;
          width: 130px;
          transition: all 0.2s ease;
          @include tablet {
            width: 80px;
            right: -90px;
          }
          @include mobile {
            width: 40px;
            right: 0px;
          }
        }
      }

      .text-box {
        width: 100%;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #fff;
        font-size: 13px;
        font-weight: 500;

        .date {
          width: 34%;
          padding: 4px 15px;
          text-align: center;
          background-color: rgba(1, 74, 153, 0.8);
          @include mobile {
            width: 100%;
          }

          span {
            padding: 0 10px;
            @include tablet {
              padding: 0 5px;
            }
          }
        }

        .text {
          width: 66%;
          display: flex;
          justify-content: flex-end;
          padding: 4px 15px;
          background-color: rgba(0, 129, 55, 0.8);
          @include mobile {
            width: 100%;
            justify-content: center;
          }

          .title {
            display: flex;
            justify-content: space-between;
            align-content: center;
            color: #fff;
            font-size: 13px;
            font-weight: 500;
            margin-right: 18px;

            &:after {
              display: inline-block;
              content: '';
              margin-top: 3px;
              width: 2px;
              height: 13px;
              margin-left: 18px;
              background-color: #fff;
            }
          }

          small {
            margin-left: 5px;
          }
        }
      }
    }

    &.gaudi {
      background: $color-bg-3
        url('assets/images/LMBanner/lmbanner-bg-gaudi.jpg') no-repeat center
        center/cover;
      > .title {
        font-size: 30px;
        padding: 20px 20px 20px 0px;
        color: #ffffff;
        line-height: 1.1em;
        font-weight: 500;
        width: 200px;
        border-right: 2px solid #ffffff;
        transition: all 0.2s ease;
        @include tablet {
          width: 150px;
          font-size: 22px;
          padding: 15px 15px 15px 0;
        }
        @include mobile {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: auto;
          font-size: 18px;
          padding: 0 0 5px 0;
          border-right: 0;
          .gaudi-logo {
            width: 90px;
            margin-right: 20px;
          }
        }
        &:after {
          display: none;
        }
      }
      .btn-more {
        :deep(.label) {
          color: $color-bg-3;
        }

        :deep(.icon) {
          color: $color-bg-3;
        }
      }
    }
  }
}
</style>
